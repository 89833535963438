import { graphql, PageProps } from "gatsby";
import React from "react";
import { SimplePage } from "../containers/SimplePage";

export default ({
  data: {
    sanitySiteSettings: { title },
  },
}: PageProps<{ sanitySiteSettings: GatsbyTypes.SanitySiteSettings }>) => {
  const description = `${title}が提供するサービスについて、ご利用者様にご案内いたします。`;
  const markdown = `## 免責事項
  ${title}（以下、「当サイト」といいます）は細心の注意を払って情報を掲載しておりますが、この情報の正確性および完全性を保証するものではありません。
  当サイトに含まれる情報または内容をご利用されたことで、直接・間接的に生じた損失に関し一切責任を負うものではありません。
  ## 著作権・商標
  当サイトに掲載されている文章、イラスト、写真、動画、その他のすべての情報は、当サイトまたは第三者が著作権を有しております。
  個人的な利用など、著作権法によって認められる場合を除き、著作権者の事前の許可なく、これらの情報を利用（複製、改変、配布など）することはできません。
  ## リンク
  当サイトへのリンクは、営利、非営利を問わず原則自由とします。ただし、以下のいずれかに該当するか、またはそのおそれがあるリンクの設定はご遠慮ください。
  - 当サイトや他者（社）を誹謗中傷したり、信用失墜を意図する内容を含んだWebサイト
  - 当サイト、または他社 (者)・他団体の著作権や商標権などの知的財産権、財産、プライバシーもしくは肖像権その他の権利を侵害する行為、または侵害するおそれのあるWebサイト
  - 当サイトのコンテンツであることが不明確となり、第三者に誤解を与える可能性があるWebサイト
  - 法律、条例、規則を含む法令または公序良俗に違反しているWebサイト
  `;

  return (
    <SimplePage
      title="サイトポリシー"
      description={description}
      markdown={markdown}
    />
  );
};

export const query = graphql`
  {
    sanitySiteSettings {
      title
    }
  }
`;
